import { useSupabaseSession } from "~/contexts/SupabaseSessionContext";
import { twMerge } from "tailwind-merge";
import { Menu } from "./Menu";
import { Logo } from "./Logo";

export const Header = ({
  title,
  progress,
  logoOnly,
  className,
  withDarkMode,
  absoluteOnDesktop,
  noSticky,
}: {
  title?: string;
  progress?: {
    stepNum: number;
    stepCount: number;
  };
  logoOnly?: boolean;
  className?: string;
  withDarkMode?: true;
  absoluteOnDesktop?: true;
  noSticky?: true;
}) => {
  const { session } = useSupabaseSession();

  return (
    <header
      className={twMerge(
        `flex top-0 w-full bg-background z-10 max-w-6xl`,
        `${
          noSticky
            ? ""
            : absoluteOnDesktop
            ? "md:absolute bg-transparent max-w-7xl"
            : "sticky"
        }`,
        className
      )}
    >
      <div className="flex gap-[10px] items-center m-4 ml-[10px] flex-grow">
        <Logo
          className={`w-[54px] md:w-[100px] ${
            withDarkMode ? "dark:brightness-[4]" : ""
          }`}
        />
        {logoOnly || (
          <div className="flex flex-col gap-2 w-full">
            {title && (
              <h2
                className={`font-bold ${
                  withDarkMode ? "dark:text-slate-200" : ""
                }`}
              >
                {title}
              </h2>
            )}
            {progress && (
              <ProgressBar
                stepNum={progress.stepNum}
                stepCount={progress.stepCount}
              />
            )}
          </div>
        )}
      </div>
      <div className="m-4 my-auto">{session && <Menu />}</div>
    </header>
  );
};

export const ProgressBar = ({
  stepNum,
  stepCount,
  singleColor,
}: {
  stepNum: number;
  stepCount: number;
  singleColor?: true;
}) => (
  <div className="flex flex-row items-center gap-1 w-full">
    {Array.from({ length: stepCount }).map((_, i) => {
      const stepI = i + 1;
      return (
        <div
          key={`progressbar-step-${stepI}`}
          className={`flex-1 h-1 rounded-full ${
            stepI < stepNum
              ? "bg-blue-800"
              : stepI === stepNum
              ? singleColor
                ? "bg-blue-800"
                : "bg-blue-500"
              : "bg-gray-200"
          }`}
        />
      );
    })}
  </div>
);
